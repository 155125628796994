/**
 *
 * Load components
 *
 */

require('bootstrap')

/**
 *
 * Load polyfill
 *
 */
 
require('./components/polyfill')

/**
 *
 * Use vue
 *
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import './registerServiceWorker'
import(/* webpackChunkName: "main-style" */ /* webpackPreload: true */ '@/styles/app.scss')

Vue.use(VueRouter)

Vue.config.productionTip = false

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: () => import(/* webpackChunkName: "accueil" */ './components/quiz/Home.vue'),
        },
        {
            path: '/:id',
            name: 'level',
            component: () => import(/* webpackChunkName: "quiz" */ './components/quiz/Quiz.vue'),
            /*beforeEnter (route, redirect, next) {
                if(document.querySelector('.confirm-js')) {
                    let confirm = document.querySelector('.confirm-js')
                    confirm.addEventListener('click', function() {
                        next()
                    })
                } else {
                    next()
                }
            }*/
        }
    ]
})

// Filter
Vue.filter('capitalize', function(text) {
    return text.charAt(0).toUpperCase() + text.slice(1)
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
