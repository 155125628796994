<template>
	<div id="app">
		<transition name="fade" mode="out-in">
			<router-view></router-view>
		</transition>
		<button class="update" v-if="updateExists" @click="refreshApp">
			Nouvelle version disponible ! Cliquez pour mettre à jour
		</button>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				refreshing: false,
				registration: null,
				updateExists: false,
			};
		},
		created () {
			document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				if (this.refreshing) return;
				this.refreshing = true;
				window.location.reload();
			});
		},
		methods: {
			showRefreshUI (e) {
				this.registration = e.detail;
				this.updateExists = true;
			},
			refreshApp () {
				this.updateExists = false;
				if (!this.registration || !this.registration.waiting) { return; }
				this.registration.waiting.postMessage('skipWaiting');
			},
		}
	}
</script>

<style lang="scss">

.update {
	position: absolute;
	bottom: 20%;
	right: 16px;
	height: 35px;
	background-color: var(--color-white);
	border: none;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .5);
	cursor: pointer;
	&:focus {
		border: none;
		outline: none;
	}
}

</style>
